import React from "react";
import "./AirFreshenerManufacture.css"; // Make sure to create or modify the CSS file if needed
import HaveAScent from "../../Components/HaveAScent";

const AirFreshenerManufacture = () => {
  return (
    <div>
      <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
        AIR FRESHENER
      </p>
      <div className="w-full h-[50vh] bg-[#FD9CB1] flex justify-center items-center">
        <p className="typing-text w-[80%] text-center text-5xl sm:m-10 text-white">
          Clean Air, Fresh Spaces
        </p>
      </div>

      {/* Section 1: Air Freshener Manufacturing Process */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col lg:flex-row items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="text-3xl my-6 font-bold">
            The Air Freshener Manufacturing Process
          </p>
          <p className="text-xl my-3">
            Air fresheners are crafted to deliver a pleasant aroma that
            eliminates bad odors and refreshes the air in any space. Our process
            begins with selecting high-quality essential oils and fragrances
            that are both long-lasting and safe for the environment.
          </p>
          <p className="text-xl my-3">
            The air freshener solution is carefully blended with a mixture of
            oils, water, and neutralizing agents to ensure the product is
            effective while maintaining a gentle and balanced scent.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://media.licdn.com/dms/image/D4D12AQEBLrkTZFXaAw/article-cover_image-shrink_720_1280/0/1670827761402?e=2147483647&v=beta&t=I-W9n2-Ztexv-SdHN7sGGBs_AHdFdZmh0PkaURxpfI8"
          alt="Air Freshener Process"
        />
      </div>

      {/* Section 2: Details About the Air Freshener */}
      <div className="w-[90%] sm:w-[70%] mx-auto my-10 flex flex-col lg:flex-row-reverse items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="mb-6 text-2xl">
            Our expert team combines the perfect ratios of essential oils and
            odor-neutralizing compounds to produce an air freshener that keeps
            your space smelling fresh all day long.
          </p>
          <p className="mb-6 text-xl">
            We prioritize eco-friendly ingredients, ensuring that each air
            freshener is not only effective but also safe for both you and the
            environment. Our air fresheners are free from harmful chemicals and
            are designed for long-lasting results.
          </p>
          <p className="mb-6 text-xl">
            With a variety of scents to choose from, our air fresheners provide
            the perfect solution for creating a comfortable and pleasant
            environment, wherever you go.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://www.thespruce.com/thmb/SU-0B6BUBaR-2ARnmD5t7G9Y_84=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/green-diy-air-fresheners-1706905_01-25c514dee9324842888dadbaf811ac9a.jpg"
          alt="Air Freshener Production"
        />
      </div>

      {/* HaveAScent Component */}
      <HaveAScent color="#FD9CB1" />
    </div>
  );
};

export default AirFreshenerManufacture;
