import React from "react";

const Section4 = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row justify-center items-center gap-6 md:gap-10 w-[90vw] md:w-[80vw] mb-20 mx-auto">
      <p className="text-black text-4xl md:text-6xl text-left md:text-left">
        We produce home fragrances.
      </p>

      <img
        className="w-full md:w-1/2 mt-28 md:my-28"
        src="https://5.imimg.com/data5/SELLER/Default/2023/3/293013673/GC/ZF/MY/3803856/white-label-perfume-500x500.jpg"
        alt=""
      />
    </div>
  );
};

export default Section4;
