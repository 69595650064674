import React from "react";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-10 px-5 sm:w-[70%] mx-auto md:px-20">
      {/* Header Section */}
      <div className="text-center">
        <p className="text-gray-600 text-lg mt-24">
          Discover exciting opportunities and become part of our journey.
        </p>
      </div>

      {/* Vacancy List */}
      <div className="mt-10">
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Current Openings
        </h2>

        <div className="bg-white shadow-md rounded-lg p-6">
          {/* Job Card */}
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-4 md:mb-0">
              <h3 className="text-xl font-medium text-gray-800">
                Product Development Manager
              </h3>
              <p className="text-gray-600">
                Location: Biravo, Narayanganj, Dhaka. | Full-Time
              </p>
            </div>
            <Link to="/career/apply" className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded transition">
              Apply Now
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Career;
