import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    country: '',
    web: '',
    email: '',
    telephone: '',
    comments: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const subject = `Request for Information from Nextcare fragrances`;
    const body = `Name: ${formData.name}\nCompany: ${formData.company}\nCountry: ${formData.country}\nWebsite: ${formData.web}\nEmail: ${formData.email}\nTelephone: ${formData.telephone}\nComments: ${formData.comments}`;

    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${`contact@nextcarefragrances.com`}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink, '_blank');
  };

  return (
    <form 
      onSubmit={handleSubmit} 
      className="bg-white p-6 rounded shadow mx-auto w-[100vw] sm:w-[70vw] xl:w-[40vw]"
    >
      <h2 className="text-2xl font-bold mb-4 text-[#cccccc]">
        Request More Information About Private Label and Download Our Corporate Brochure
      </h2>

      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="company" className="block text-gray-700 font-bold mb-2">
          Company
        </label>
        <input
          type="text"
          id="company"
          name="company"
          value={formData.company}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="country" className="block text-gray-700 font-bold mb-2">
          Country
        </label>
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="web" className="block text-gray-700 font-bold mb-2">
          Web
        </label>
        <input
          type="text"
          id="web"
          name="web"
          value={formData.web}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
          Email
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="telephone" className="block text-gray-700 font-bold mb-2">
          Telephone
        </label>
        <input
          type="text"
          id="telephone"
          name="telephone"
          value={formData.telephone}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label htmlFor="comments" className="block text-gray-700 font-bold mb-2">
          Comments
        </label>
        <textarea
          id="comments"
          name="comments"
          value={formData.comments}
          onChange={handleChange}
          className="border-b border-b-black rounded w-full py-2 px-4 focus:outline-none focus:border-blue-500"
          required
        />
      </div>

      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          id="legal-terms"
          name="legal-terms"
          className="mr-2"
        />
        <label htmlFor="legal-terms">I have read and accept the legal terms</label>
      </div>

      <button type="submit" className="bg-black hover:bg-secondary ease-in-out duration-200 text-white font-bold py-2 px-4 rounded w-full">
        Send
      </button>
    </form>
  );
};

export default ContactForm;
