import React from "react";
import featureImage from "../Images/manufacture.png"

const ManufacturerSec3 = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 w-[90vw] md:w-[80vw] mx-auto">
      <div className="flex flex-col md:flex-row gap-10 mt-32">
        <img
          alt=""
          className="md:w-[50%]"
          src="https://images.herzindagi.info/image/2024/Feb/perfumes-to-gift-girlfriend.jpg"
        />
        <div className="md:w-[50%]">
          <p className="mb-10 text-2xl md:text-4xl">
          Do I benefit from collaborating with a full-service perfume manufacturer?
          </p>
          <p className="text-xl md:text-2xl">
          We know we have the means and integrated processes to launch your fragrance to the market with the highest quality standards and comply with all the parameters.
          </p>
          <p className="text-xl md:text-2x mt-6">
          Throughout the product development and manufacture, the Full-Service concept is applied to customers that you feel listened to, cared for, and accompanied at all stages of the process.
          </p>
          <p className="text-xl md:text-2x mt-6">
          This is what has made us exceptional partners when it comes to designing and manufacturing perfumes for third parties.
          </p>
        </div>
      </div>
    {/* .......................section 2....................... */}
      <div className="flex flex-col md:flex-row-reverse gap-10 mt-[200px]">
        <img
          alt=""
          className="md:w-[50%]"
          src="https://www.feelingsexy.com.au/images/blog/MEN-FRAGRANCE.JPG"
        />
        <div className="md:w-[50%]">
          <p className="mb-10 text-2xl md:text-4xl mt-4">How is this full-service process different?</p>
          <p className="text-xl md:text-2x">
          You will have a roadmap in the hands of the best collaborators you can have. Not in vain; at Nextcare Perfumes, we have the most appropriate facilities and machinery to offer the excellent service that defines us.
          </p>
          <p className="text-xl md:text-2x mt-10">
          All this, added to qualified staff, first-class facilities, and a great working capacity, makes us a perfect ally to manufacture perfumes for third parties, fashion brands, retail companies, and companies that wants to boost their income statement with a line of fragrances.
          </p>
        </div>
      </div>

    </div>
  );
};

export default ManufacturerSec3;
