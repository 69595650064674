import React from "react";
import perfumes from '../../Images/perfumes.gif';

const Section5 = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-6 md:gap-10 px-4 md:px-[20vw] mx-auto bg-secondary text-[#000] py-10 md:py-0" >
      <div className="text-left md:text-left max-w-md text-white">
        <p className="text-2xl md:text-3xl font-bold mb-6 text-center md:text-left ">
          Our packages start at low minimums
        </p>
        <p className="mb-4 text-lg">
          The entire process of launching your perfume line takes 3-5 months.
        </p>
        <p className="mb-4 text-lg">Here's how it works:</p>
        <p className="text-lg">
          ✓ Your vision & scent are brought to life by our master perfumer and
          tweaked until you're satisfied after discussing your brand goals and
          needs <br />
          ✓ The perfume is then left to macerate (cure) for at least 45 days
          <br />
          ✓ A bottle is selected, labels, stickers & cap are finalized
          <br />
          ✓ Our artists design and create the ideal packaging for your fragrance
          according to your vision
          <br />
          ✓ Your fragrance is poured into the bottles, sealed, labeled and
          packaged. Goods are shipped from our facility in Dallas, Texas
          <br />
        </p>
      </div>

      <img
        className="w-full md:w-1/2 mt-6 md:mt-0"
        src={perfumes}
        alt=""
      />
    </div>
  );
};

export default Section5;
