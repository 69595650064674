import React from "react";
import "./PerfumeManufacture.css";
import HaveAScent from "../../Components/HaveAScent";

const PerfumeManufacture = () => {
  return (
    <div>
      <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
        PERFUMEs
      </p>
      <div className="w-full h-[50vh] bg-accent flex justify-center items-center">
        <p className="typing-text text-5xl m-10 text-white">
          Scented to Inspire, Designed to Last
        </p>
      </div>

      <div className="w-[90%] sm:w-[70%] mx-auto my-10 flex flex-col sm:flex-row items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="text-4xl my-6 font-bold">The Perfume Manufacturing Process</p>
          <p className="text-2xl my-3">
            Making a perfume involves collecting ingredients, extracting oils, blending, aging and quality control. 
          </p>
          <p className="text-2xl my-3">
            Blending aromatic compounds, solvents, and fixatives to create unique and long-lasting scents.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://sp-ao.shortpixel.ai/client/to_auto,q_glossy,ret_img/https://www.scentbird.com/blog/wp-content/uploads/2017/08/Joya-featured-image.png"
          alt=""
        />
      </div>

      <div className="w-[90%] sm:w-[70%] mx-auto my-10 flex flex-col sm:flex-row-reverse items-center gap-10">
        <div className="sm:w-[50%]">
        <p className="mb-6 text-xl">
            We handpick various notes and precisely blend them with exact percentages to curate the perfect fragrance that soothes your senses.
          </p>
          <p className="mb-6 text-xl">
            Our team of expert perfumers carefully selects each note—from fresh top notes to warm base notes—to ensure a harmonious balance in every bottle. We believe in delivering a scent that not only lasts but also evokes emotions and unforgettable experiences.
          </p>
          <p className="mb-6">
            Whether it’s the floral hints that relax your mind or the woody undertones that add depth, each fragrance is designed to complement your unique personality.
          </p>
          {/* <p className="mb-6">
            Blending science with art, we meticulously calculate the ratios of essential oils and aromatic compounds to craft fragrances that resonate with timeless elegance and modern style.
          </p> */}
          <p className="mb-6">
            Our commitment to quality ensures that each bottle reflects the dedication, precision, and creativity that goes into every fragrance we produce.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://julesandvetiver.com/cdn/shop/files/4a-Jules_Vetiver_Summer_Shoot_with_Hands_R1_DEN_4717_2048x1365_2b682442-7ac6-41c5-b021-26336946cec5.jpg?v=1653404193"
          alt=""
        />
      </div>
      
      <HaveAScent color="#FF8672" />
    </div>
  );
};

export default PerfumeManufacture;
