import React from "react";
import "./Banner.css";
import banner from "../../Images/banner.mp4";

const Banner = () => {
  return (
    <div className="banner">
    <video autoPlay muted loop>
      <source src={banner} type="video/mp4" />
    </video>
    {/* Optional overlay */}
    <div className="overlay"></div>
  </div>
  );
};

export default Banner;
