// import PaintAnimation from "../PaintingAnimation/PaintingAnimation";
// import paintImage from "../../Images/paint-strokes-green.png";
import privatelabel from "../../Images/private-label-ai.webp";
import { Link } from "react-router-dom";

const HomeSec4 = () => {
  return (
    <div className="flex flex-col xl:flex-row-reverse justify-center xl:h-[80vh] items-center mt-10 w-[90vw] sm:w-[80vw] xl:w-[75vw] mx-auto">
      <div className="w-[100%] sm:w-[80%] p-2 xl:w-[80%] md:p-10 lg:p-12 xl:p-16 my-auto">
        <p className="text-3xl sm:text-4xl lg:text-5xl font-bold lg:w-[100%] mx-auto mb-10 text-secondary">
          Private Label
        </p>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl ">
          Our private label services enable you to create a unique product under
          your brand. We provide end-to-end solutions, from developing custom
          formulations to designing premium packaging that aligns with your
          brand's vision.
        </p>
        <div className="w-[100%] lg:w-[70%] mb-10 mt-6 md:hidden">
          <img alt="Private Label" src={privatelabel} />
        </div>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl mt-6">
          With a focus on quality and consistency, our team ensures that your
          products meet the highest standards, delivering exceptional results
          that resonate with your customers and enhance your brand identity.
        </p>
        <div className="mt-10">
          <Link
            to="/private-label"
            className="relative inline-block border border-secondary hover:text-white text-secondary lg:text-secondary bg-white font-bold py-4 px-6 sm:px-10 sm:py-4 rounded-lg overflow-hidden ease-in-out duration-200 border-[2px] group"
          >
            <span className="absolute inset-0 bg-secondary w-0 h-full transition-all duration-500 ease-out group-hover:w-full"></span>
            <span className="relative z-10">Want To Know More?</span>
          </Link>
        </div>
      </div>
      <div className="w-[90%] lg:w-[70%] mb-10 hidden md:block">
        <img alt="Private Label" src={privatelabel} />
      </div>
    </div>
  );
};

export default HomeSec4;
