import React, { useState } from "react";
import { Link } from "react-router-dom";
import perfume from "../../Images/Perfume.png";
import bodySpray from "../../Images/body-spray.png";
import airFreshner from "../../Images/air-freshner.png";
import privateLabel from "../../Images/Private-label.png";
import WhiteLabel from "../../Images/white-label.png";
import contract from "../../Images/contract.png";

const WhatWeDo = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="nav-link hover:text-accent ease-in-out duration-200 relative cursor-pointer lg:h-[80px] flex items-center">
        WHAT WE DO
      </div>
      <div
        className={`flex-col absolute bg-secondary z-10 mx-1 p-4 border border-white ${
          visible ? "flex" : "hidden"
        }`}
      >
        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/perfume-manufacture"
        >
          <img className="w-[40px]" alt="" src={perfume} />
          <p>PERFUMES</p>
        </Link>
        <hr className="text-white border border my-2" />
        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/body-spray-manufacture"
        >
          <img className="w-[40px]" alt="" src={bodySpray} />
         <p> BODY SPRAY</p>
        </Link>
        <hr className="text-white border border my-2" />

        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/air-freshner-manufacture"
        >
          <img className="w-[40px]" alt="" src={airFreshner} />
          <p>AIR FRESHNER</p>
        </Link>
        <hr className="text-white border border my-2" />

        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/private-label"
        >
          <img className="w-[40px]" alt="" src={privateLabel} />
          <p>PRIVAT LABEL</p>
        </Link>
        <hr className="text-white border border my-2" />

        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/white-label-manufacture"
        >
          <img className="w-[40px]" alt="" src={WhiteLabel} />
          <p>WHITE LABEL</p>
        </Link>
        <hr className="text-white border border my-2" />

        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/contract-manufacture"
        >
          <img className="w-[40px]" alt="" src={contract} />
          <p>CONTRACT  MANUFACTURE</p>
        </Link>
      </div>
    </div>
  );
};

export default WhatWeDo;
