import React from "react";

const JoinTheTeam = () => {
  return (
    <div>
      {/* Title Section */}
      <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
        JOIN OUR TEAM
      </p>

      {/* Tagline Section */}
      <div className="w-full h-[50vh] bg-[#FFB800] flex justify-center items-center">
        <p className="typing-text w-[80%] text-center text-5xl sm:m-10 text-white">
          Be Part of a Dynamic and Passionate Team
        </p>
      </div>

      {/* Section 1: Introduction */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col lg:flex-row items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="text-4xl my-6 font-bold">Why Join Us?</p>
          <p className="text-xl my-3">
            At our company, we value creativity, dedication, and passion. We’re
            always on the lookout for individuals who are enthusiastic about
            making a difference and driving positive change.
          </p>
          <p className="text-xl my-3">
            Joining us means you’ll be part of a collaborative environment where
            your ideas and contributions are valued. We offer growth
            opportunities and a chance to work on meaningful projects.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDeU55Ld8Z1QVNRjbT3RMzs5ZVbI1l-3tAbA&s"
          alt="Join Our Team"
        />
      </div>

      {/* Section 2: What We Offer */}
      <div className="w-[90%] sm:w-[70%] mx-auto my-10 flex flex-col lg:flex-row-reverse items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="text-4xl my-6 font-bold">What We Do</p>
          <p className="text-xl my-3">
            At our company, we specialize in crafting high-quality products from
            concept to completion. We manage every step of the production
            process, from research and development to manufacturing and
            packaging, ensuring the highest standards of quality and innovation.
          </p>
          <p className="text-xl my-3">
            We work closely with our clients to understand their unique needs
            and bring their visions to life. Whether it’s creating new formulas,
            sourcing sustainable materials, or optimizing production techniques,
            we are dedicated to delivering exceptional products that exceed
            expectations.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://t3.ftcdn.net/jpg/04/40/93/40/360_F_440934026_wZt6WJ2dkG4kYJ9Owsngill0iLbbsM43.jpg"
          alt="Office Environment"
        />
      </div>

      {/* Section 3: Our Hiring Process */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col lg:flex-row items-center gap-10 mt-10">
        {/* Left side - Text content */}
        <div className="lg:w-[50%]">
          <p className="text-4xl my-6 font-bold text-center lg:text-left">
            Join Our Team
          </p>
          <p className="text-2xl my-3">
            We're always looking for passionate, innovative, and driven
            individuals to join our team. At our company, we believe in a
            culture of growth, collaboration, and excellence.
          </p>
          <p className="text-2xl my-3">
            If you're ready to take the next step in your career, we’d love to
            hear from you! Fill out the form, and our team will be in touch with
            you soon.
          </p>
        </div>

        {/* Right side - Form */}
        <div className="lg:w-[50%] bg-gray-100 p-6 rounded-lg shadow-lg ">
          <form className="">
            <div className="mb-4">
              <label
                className="block text-xl font-medium text-gray-700 mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Your Name"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-xl font-medium text-gray-700 mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Your Email"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-xl font-medium text-gray-700 mb-2"
                htmlFor="position"
              >
                Position
              </label>
              <input
                type="text"
                id="position"
                name="position"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Position of Interest"
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-xl font-medium text-gray-700 mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                placeholder="Tell us a bit about yourself"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-[#FFB800] text-white text-xl font-bold py-2 rounded-lg hover:bg-black transition duration-300"
            >
              Submit Application
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default JoinTheTeam;
