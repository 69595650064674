import React from "react";

const ScentedMarketing = () => {
  return (
    <div>
      <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
        SCENTED MARKETING
      </p>
      {/* Banner Section */}
      <div className="w-full h-[50vh] bg-[#6E276C] flex justify-center items-center">
        <p className="text-5xl text-white text-center px-6">
          Creating Memorable Brand Experiences Through Scent
        </p>
      </div>

      {/* Intro Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-16 flex flex-col lg:flex-row items-center gap-10">
        <img
          className="w-full lg:w-1/2"
          src="https://cdn-prod.medicalnewstoday.com/content/images/articles/319/319615/smoke-abstract-image.jpg"
          alt="Scent Influence"
        />
        <div className="w-full lg:w-1/2 text-lg">
          <p className="text-4xl font-bold mb-4">
            The Power of Smell
          </p>
          <p>
            A scent has the ability to influence behavior and trigger memories
            almost instantaneously. When smell is combined with other marketing
            cues, it can amplify a brand experience and establish a long-lasting
            connection with consumers.
          </p>
        </div>
      </div>

      {/* Brand Differentiation Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-16 flex flex-col lg:flex-row-reverse items-center gap-10">
        <img
          className="w-full lg:w-1/2"
          src="https://media.licdn.com/dms/image/v2/D5612AQFmq7elW0R6Fg/article-cover_image-shrink_720_1280/article-cover_image-shrink_720_1280/0/1683798376483?e=2147483647&v=beta&t=J7l3OTDdq1PP6b4_Flw6lO29m3mU5hk-58WKQTEzego"
          alt="Brand Differentiation"
        />
        <div className="w-full lg:w-1/2 text-lg">
          <p className="text-4xl font-bold mb-4">
            Stand Out with Scent Marketing
          </p>
          <p>
            At Nextcare Fragrances, we enable you to differentiate your brand
            and increase loyalty with Scent Marketing. Imagine a wonderful
            fragrance – where did you smell it? Our goal is to tap into this
            potential for your company, increasing your bottom line and leaving
            a lasting impression on customers.
          </p>
        </div>
      </div>

      {/* Emotional Connection Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-16 flex flex-col lg:flex-row items-center gap-10">
        <img
          className="w-full lg:w-1/2"
          src="https://fragrancescience.com/wp-content/uploads/7-SCIENCE-OF-SMELL2000.jpg"
          alt="Emotional Connection"
        />
        <div className="w-full lg:w-1/2 text-lg">
          <p className="text-4xl font-bold mb-4">
            Building Emotional Connections
          </p>
          <p>
            Scent marketing helps customers associate positive emotions with
            your brand. A subtle yet powerful strategy, it enhances customer
            experiences by adding a pleasant fragrance, making their interaction
            with your brand memorable.
          </p>
        </div>
      </div>

      {/* Customized Scent Solutions Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-16 flex flex-col lg:flex-row-reverse items-center gap-10">
        <img
          className="w-full lg:w-1/2"
          src="https://www.goldn.com/images/small_Create_Custom_Scent_Beauty_Brand_Goldn_fae6a48cbe/small_Create_Custom_Scent_Beauty_Brand_Goldn_fae6a48cbe.png"
          alt="Custom Scent Solutions"
        />
        <div className="w-full lg:w-1/2 text-lg">
          <p className="text-4xl font-bold mb-4">Tailored Scent Solutions</p>
          <p>
            We collaborate with brand owners to design and blend unique
            fragrances. Our solutions include specialized systems for large
            spaces, integrated into your HVAC systems, as well as portable
            plug-and-play options for smaller spaces.
          </p>
        </div>
      </div>

      {/* Support and Maintenance Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-16 flex flex-col lg:flex-row items-center gap-10">
        <img
          className="w-full lg:w-1/2"
          src="https://blog.mindgrub.com/hubfs/Importance%20of%20Ongoing%20Technical.jpg"
          alt="Support and Maintenance"
        />
        <div className="w-full lg:w-1/2 text-lg">
          <p className="text-4xl font-bold mb-4">
            Ongoing Support and Maintenance
          </p>
          <p>
            Our technical team provides seamless installation and reliable
            maintenance services. With monthly or bimonthly visits for fragrance
            replenishment, you’ll receive all the support needed to keep your
            scent marketing on point.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScentedMarketing;
