import React, { useState } from 'react';

const HaveAScent = ({color}) => {
  const [formData, setFormData] = useState({
    fullName: '',
    company: '',
    email: '',
    phone: '',
    country: '',
    message: '',
    termsAccepted: false
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const subject = `Have a scent in mind`;
    const body = `Name: ${formData.name}\nCompany: ${formData.company}\nCountry: ${formData.country}\nWebsite: ${formData.web}\nEmail: ${formData.email}\nTelephone: ${formData.telephone}\nComments: ${formData.comments}`;

    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${`contact@nextcarefragrances.com`}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink, '_blank');
  };

  return (
    <div style={{ backgroundColor: color }} className={`w-full h-auto flex justify-center py-16 px-6`}>
      <div className="max-w-4xl w-full [#95D4E9]">
        {/* Title */}
        <div className="text-center mb-10">
          <h1 className="text-5xl font-bold text-black">
            Have a scent in mind?
          </h1>
          <p className="text-xl font-semibold text-black">
            We're here to help you bring it to life
          </p>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="bg-white rounded-lg p-8 shadow-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Full Name*
              </label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="What is your full name?"
                className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#000]"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Company
              </label>
              <input
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
                placeholder="Who do you represent?"
                className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#000]"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Email Address*
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="What is your email address?"
                className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#000]"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Phone Number*
              </label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="What is your phone number?"
                className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#000]"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                City / Country*
              </label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="Where are you located?"
                className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#000]"
                required
              />
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Message*
            </label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="How can we help?"
              className="w-full border border-gray-300 rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#000]"
              rows="5"
              required
            ></textarea>
          </div>
          <div className="mb-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
                className="text-[#95D4E9] focus:ring-[#000]"
                required
              />
              <span className="ml-2 text-gray-700">
                I have read and agree to the{" "}
                <a style={{ color: color }} href="#" className="text-[#000] underline">
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a style={{ color: color }} href="#" className="text-[#000] underline">
                  Privacy Policy
                </a>
              </span>
            </label>
          </div>
          <div className="text-center">
            <button
              type="submit"
              style={{ backgroundColor: color }}
              className="text-white font-bold py-3 px-6 rounded-full hover:bg-black transition duration-300"
            >
              SEND MESSAGE
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HaveAScent;
