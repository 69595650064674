import React from "react";
import "./PrivateLabel.css";
import Banner from "../../Components/Banner/Banner";
import Section1 from "../../Components/Section1/Section1";
import Section2 from "../../Components/Section2/Section2";
import Section3 from "../../Components/Section3/Section3";
import Section4 from "../../Components/Section4/Section4";
import Section5 from "../../Components/Section5/Section5";
import ContactForm from "../../Components/ContactForm/ContactForm";

const PrivateLabel = () => {
  return (
    <div>
      <Banner />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </div>
  );
};

export default PrivateLabel;
