import React, { useState } from "react";
import { Link } from "react-router-dom";
import perfume from "../../Images/Perfume.png";
import bodySpray from "../../Images/body-spray.png";
import meet from "../../Images/meet-the-team.png";
import join from "../../Images/join-the-team.png";


const WhoWeAre = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="nav-link hover:text-accent ease-in-out duration-200 relative cursor-pointer lg:h-[80px] flex items-center z-1">
        WHO WE ARE
      </div>
      <div
        className={`flex-col absolute bg-secondary mx-1 p-4 border border-white ${
          visible ? "flex" : "hidden"
        }`}
      >
        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/meet-the-team"
        >
          <img className="w-[40px]" alt="" src={meet} />
          <p>MEET THE TEAM</p>
        </Link>
        <hr className="text-white border border my-2" />
        <Link
          className="nav-link hover:text-black ease-in-out duration-200 flex items-center gap-4"
          to="/join-the-team"
        >
          <img className="w-[40px]" alt="" src={join} />
         <p> JOIN THE TEAM</p>
        </Link>
      </div>
    </div>
  );
};

export default WhoWeAre;
