import React from "react";
import logo from "../../Images/nextcare-1.png";
import Manufacturer from './../../Pages/Manufacturer/Manufacturer';

const Footer = () => {
  return (
    <footer className="bg-black py-10 md:py-16">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-4 md:px-8">
        <div className="flex flex-col items-center lg:items-start mb-8 md:mb-0">
          <img
            src={logo}
            alt="Auria Perfumes"
            className="w-[160px] md:w-[200px] mb-4"
          />
          {/* <p className="text-white text-center md:text-left">Creating perfumes with soul</p> */}
        </div>

        <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 text-center md:text-left">
          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              What We Do
            </h3>
            <ul className="space-y-2">
              <li>
                <a href="/perfume-manufacture" className="text-white hover:underline">
                  Perfume Manufacture
                </a>
              </li>
        
              <li>
                <a href="/body-spray-manufacture" className="text-white hover:underline">
                  Body Spray Manufacture
                </a>
              </li>
        
              <li>
                <a href="/air-freshner-manufacture" className="text-white hover:underline">
                  Air Freshner Manufacture
                </a>
              </li>
              <li>
                <a href="/private-label" className="text-white hover:underline">
                  Private Label
                </a>
              </li>
        
              {/* <li><a href="/" className="text-white hover:underline">Nail Polishes</a></li> */}
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              Manufacturer
            </h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-white hover:underline">
                  Full Service Manufacturing
                </a>
              </li>
              <li>
                <a href="/contract-manufacture" className="text-white hover:underline">
                  Contract Manufacturing
                </a>
              </li>
              <li>
                <a href="/white-label-manufacture" className="text-white hover:underline">
                  White Label Manufacturing
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4 text-white">
              Who We Are
            </h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-white hover:underline">
                  Who we are
                </a>
              </li>
              <li>
                <a href="/meet-the-team" className="text-white hover:underline">
                  Meet The Team
                </a>
              </li>
              <li>
                <a href="/join-the-team" className="text-white hover:underline">
                  Join The Team
                </a>
              </li>
              <li>
                <a href="/career" className="text-white hover:underline">
                  Career
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col items-center mt-10 lg:mt-2 lg:items-start text-center lg:text-left">
          <div className={`w-[180px] flex items-center justify-center lg:justify-start gap-4 mb-6`}>
            <a href="/" target="_blank" rel="noreferrer">
              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="#ffffff"
                  d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/nextcarefragrances"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="#ffffff"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                />
              </svg>
            </a>
            <a
              href="https://www.youtube.com/@nextcarefragrances"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="#ffffff"
                  d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
                />
              </svg>
            </a>
          </div>
          <p className="text-white">Biravo, Narayanganj, Dhaka.</p>
          <p className="text-white">contact@nextcarefragrances.com</p>
          <p className="text-white">+88 01404-403396</p>
        </div>
      </div>

      <div className="mt-8 border-t border-[#ffffff] pt-4 text-center">
        <p className="text-white">
          © Nextcare Fragrances. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
