// import PaintAnimation from "../PaintingAnimation/PaintingAnimation";
// import paintImage from "../../Images/paint-strokes-red.png";
import perfumes from "../../Images/perfume-ai.webp";
import { Link } from "react-router-dom";

const HomeSec1 = () => {
  return (
    <div className="flex flex-col xl:flex-row justify-center xl:h-[80vh] items-center mt-10 w-[90vw] sm:w-[80vw] xl:w-[75vw] mx-auto">
      <div className="w-[100%] sm:w-[80%] p-2 xl:w-[80%] md:p-10 lg:p-12 xl:p-16 my-auto">
        <p className="text-3xl sm:text-4xl xl:text-5xl font-bold xl:w-[100%] mx-auto mb-10 text-secondary">
          Perfumes
        </p>
        <p className="xl:w-[100%] mx-auto text-xl md:text-2xl ">
          At Nextcare Perfumes & Cosmetics, we pride ourselves on creating
          exceptional fragrances that are uniquely tailored to your brand's
          vision. Our team combines artistry and innovation to deliver perfumes
          that not only meet but exceed expectations.
        </p>
        <div className="w-[100%] mt-6 xl:w-[70%] mb-10 md:hidden">
          <img alt="Perfume Manufacture" src={perfumes} />
        </div>
        <p className="xl:w-[100%] mx-auto text-xl md:text-2xl mt-6 ">
          {" "}
          We specialize in manufacturing high-quality perfumes tailored to your
          brand's identity.
        </p>
        <div className="mt-10">
          <Link
            to="/perfume-manufacture"
            className="relative inline-block border border-secondary hover:text-white text-secondary lg:text-secondary bg-white font-bold py-4 px-6 sm:px-10 sm:py-4 rounded-lg overflow-hidden ease-in-out duration-200 border-[2px] group"
          >
            <span className="absolute inset-0 bg-secondary w-0 h-full transition-all duration-500 ease-out group-hover:w-full"></span>
            <span className="relative z-10">Want To Know More?</span>
          </Link>
        </div>
      </div>
      <div className="w-[90%] xl:w-[70%] mb-10 hidden md:block">
        <img alt="Perfume Manufacture" src={perfumes} />
      </div>
    </div>
  );
};

export default HomeSec1;
