// import PaintAnimation from "../PaintingAnimation/PaintingAnimation";
// import paintImage from "../../Images/paint-strokes-pink.png";
import airfreshner from "../../Images/air-freshner-ai.webp";
import { Link } from "react-router-dom";

const HomeSec3 = () => {
  return (
    <div className="flex flex-col xl:flex-row justify-center xl:h-[80vh] items-center mt-10 w-[90vw] sm:w-[80vw] xl:w-[75vw] mx-auto">
      <div className="w-[100%] sm:w-[80%] p-2 xl:w-[80%] md:p-10 lg:p-12 xl:p-16 my-auto">
        <p className="text-3xl sm:text-4xl lg:text-5xl font-bold lg:w-[100%] mx-auto mb-10 text-secondary">
          Air Freshener
        </p>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl ">
          We produce a wide range of air fresheners designed to create pleasant
          environments. Our air fresheners are formulated with high-quality,
          long-lasting fragrances that enhance any space, whether it's at home,
          in the office, or in vehicles.
        </p>
        <div className="w-[100%] lg:w-[70%] mb-10 mt-6 md:hidden">
        <img
          alt="Air Freshener Manufacture"
          src={airfreshner}
        />
      </div>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl mt-6">
          Our team works closely with you to create custom solutions that meet
          your unique needs, providing top-notch manufacturing services with
          attention to detail at every stage.
        </p>
        <div className="mt-10">
          <Link
            to="/air-freshner-manufacture"
            className="relative inline-block border border-secondary hover:text-white text-secondary lg:text-secondary bg-white font-bold py-4 px-6 sm:px-10 sm:py-4 rounded-lg overflow-hidden ease-in-out duration-200 border-[2px] group"
          >
            <span className="absolute inset-0 bg-secondary w-0 h-full transition-all duration-500 ease-out group-hover:w-full"></span>
            <span className="relative z-10">Want To Know More?</span>
          </Link>
        </div>
      </div>
      <div className="w-[90%] lg:w-[70%] mb-10 hidden md:block">
        <img
          alt="Air Freshener Manufacture"
          src={airfreshner}
        />
      </div>
    </div>
  );
};

export default HomeSec3;
