import React from "react";
import { Link } from "react-router-dom";

const MeetTheTeam = () => {
  // Array of employee objects
  const teamMembers = [
    {
      name: "John Doe",
      role: "CEO",
      image: "https://via.placeholder.com/150",
      description: "John has over 20 years of experience in the industry and leads our team with passion and vision.",
    },
    {
      name: "Jane Smith",
      role: "CTO",
      image: "https://via.placeholder.com/150",
      description: "Jane is an expert in technology and innovation, ensuring our products stay ahead of the curve.",
    },
    {
      name: "Michael Brown",
      role: "Head of Marketing",
      image: "https://via.placeholder.com/150",
      description: "With a flair for creativity, Michael drives our marketing strategies and ensures our brand shines.",
    },
    {
      name: "Emily Johnson",
      role: "Product Manager",
      image: "https://via.placeholder.com/150",
      description: "Emily is dedicated to understanding customer needs and delivering exceptional products.",
    },
    {
      name: "David Wilson",
      role: "Lead Developer",
      image: "https://via.placeholder.com/150",
      description: "David leads our development team, focusing on building innovative solutions.",
    },
    {
      name: "Sarah Brown",
      role: "UX/UI Designer",
      image: "https://via.placeholder.com/150",
      description: "Sarah creates user-friendly designs that enhance the customer experience.",
    },
  ];

  return (
    <div>
      {/* Banner Section */}
      <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
        MEET THE TEAM
      </p>
      <div className="w-full h-[50vh] bg-[#96D4E9] flex justify-center items-center">
        <p className="typing-text w-[80%] text-center text-5xl sm:m-10 text-white">
          A Team of Experts Committed to Excellence
        </p>
      </div>

      {/* Team Introduction Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col">
        <p className="text-4xl my-6 font-bold text-center">
          Meet Our Dedicated Team
        </p>
        <p className="text-2xl my-3 text-center">
          Our team comprises industry experts who are passionate about delivering the best products and services to our customers. Together, we strive for excellence in everything we do.
        </p>
      </div>

      {/* Team Members Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-col items-center">
            <img
              className="w-[150px] h-[150px] rounded-full mb-4"
              src={member.image}
              alt=""
            />
            <p className="text-2xl font-bold">{member.name}</p>
            <p className="text-lg text-gray-500">{member.role}</p>
            <p className="text-center mt-3">{member.description}</p>
          </div>
        ))}
      </div>

      {/* Conclusion Section */}
      <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col">
        <p className="text-4xl my-6 font-bold text-center">
          Join Us in Our Journey
        </p>
        <p className="text-2xl my-3 text-center">
          Our team is always growing. We are looking for talented individuals to join us in our mission to create outstanding products.
        </p>
        <p className="text-2xl my-3 text-center">
          If you are interested in becoming a part of our family, check out our job openings and apply today!
        </p>
        <Link to="/join-the-team" className="text-center py-4 bg-[#96D4E9] text-white hover:bg-black ease-in-out duration-200 w-[260px] mx-auto my-10 rounded-full font-bold">JOIN US</Link>
      </div>
    </div>
  );
};

export default MeetTheTeam;
