import React from "react";
import "./BodySprayManufacture.css";
import HaveAScent from "../../Components/HaveAScent";

const BodySprayManufacture = () => {
  return (
    <div>
      <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
        BODY SPRAY
      </p>
      <div className="w-full h-[50vh] bg-[#95D4E9] flex justify-center items-center">
        <p className="typing-text w-[80%] text-center text-5xl sm:m-10 text-white">
          Freshness that Lasts, Style that Speaks
        </p>
      </div>

      <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col lg:flex-row items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="text-4xl my-6 font-bold">
            The Body Spray Manufacturing Process
          </p>
          <p className="text-2xl my-3">
            Body sprays are formulated to offer a lighter, more refreshing scent
            compared to traditional perfumes. The process involves blending
            essential oils, water, and alcohol with skin-friendly ingredients.
          </p>
          <p className="text-2xl my-3">
            Our body sprays are designed to be light, portable, and suitable for
            everyday use. They undergo rigorous testing to ensure long-lasting
            freshness.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://unilogo.com.pl/wp-content/uploads/2023/02/perfume-production-1080x675.jpeg"
          alt="Body Spray Process"
        />
      </div>

      <div className="w-[90%] sm:w-[70%] mx-auto my-10 flex flex-col lg:flex-row-reverse items-center gap-10">
        <div className="sm:w-[50%]">
          <p className="mb-6 text-2xl">
            Our experts carefully blend the ingredients to provide a refreshing,
            everyday scent that complements your lifestyle.
          </p>
          <p className="mb-6 text-2xl">
            Each body spray is infused with long-lasting fragrance technology,
            ensuring that the scent lingers throughout the day without being
            overpowering. Our unique formulations are designed to keep you
            feeling fresh and energized, no matter the occasion.
          </p>
          <p className="mb-6 text-2xl">
            Whether it's a splash of freshness in the morning or a boost of
            confidence before a night out, our body sprays are crafted to
            deliver an instant fragrance uplift.
          </p>
          <p className="mb-6 text-2xl">
            We prioritize using skin-friendly ingredients, ensuring that each
            spray not only smells great but is also gentle on your skin.
          </p>
        </div>
        <img
          className="sm:w-[50%]"
          src="https://www.royalaroma.com/wp-content/themes/aroma/images/indusrty-oil.jpg"
          alt="Body Spray Production"
        />
      </div>

      <HaveAScent color="#95D4E9" />
    </div>
  );
};

export default BodySprayManufacture;
