import React from "react";
import featureImage from "../Images/manufacture.png"

const ManufacturerSec1 = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-10 w-[90vw] md:w-[80vw] mx-auto">
      <p className="text-center text-2xl md:text-5xl md:w-[700px]">
        Are you a retailer? <br /> We are Full service Perfumes manufacturers
      </p>
      <p className="text-center mt-10 text-xl">We take care of your project.</p>
      <div className="flex flex-col md:flex-row gap-10 mt-10">
        <img
          alt=""
          className="md:w-[50%]"
          src="https://media-afr-cdn.oriflame.com/contentImage?externalMediaId=e51524d9-b8a7-4897-8559-5e6b0f88691a&name=top_7_things_you_did_not_know_about_oriflame_perfumes&inputFormat=jpg"
        />
        <div className="md:w-[50%]">
          <p className="mb-10 text-xl md:text-3xl">
            At Nextcare Perfumes, we offer a full-service concept so that
            creating your perfume is an integral, successful experience that you
            don’t have to worry about, except for sharing with our team the
            guidelines and objectives you want to achieve with your fragrance.
          </p>
          <p className="text-xl md:text-3xl">
            With the Full-Service concept, we take responsibility for all phases
            of the process of developing and creating a perfume:
          </p>
        </div>
      </div>
    {/* .......................section 2....................... */}
      <div className="flex flex-col md:flex-row-reverse gap-10 mt-[200px]">
        <img
          alt=""
          className="md:w-[50%]"
          src="https://cdn.shopify.com/s/files/1/0883/0828/5769/files/Top_10_perfumes_brands_1024x1024.webp?v=1721068399"
        />
        <div className="md:w-[50%]">
          <p className="mb-10 text-2xl md:text-5xl mt-4">What does 'Full Service' mean?</p>
          <p className="text-xl md:text-2xl">
            It means that you bring a brand or an idea, and we give you back a
            fragrance of your own, personalized.
          </p>
          <p className="text-xl md:text-2xl mt-10">
            At Nextcare Perfumes, we are specialists in the production and
            packaging of fragrances, and our FULL-SERVICE model allows us to
            take every step with personalized attention and the security of
            seeing the project through to completion: composing the olfactory
            score, designing the bottle and packaging;
          </p>
        </div>
      </div>

      {/* ......................section 3............... */}
      <div className=" mt-10 md:mt-[200px]">
        <img alt="" src={featureImage}/>
      </div>


    </div>
  );
};

export default ManufacturerSec1;
