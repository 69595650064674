import React from "react";
import perfume from "../../Images/Your.jpg"

const Section1 = () => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center mt-10 w-[90vw] md:w-[80vw] mx-auto">
      <img
        className="w-full md:w-1/2 mb-6 md:mb-0 md:mr-8"
        src={perfume}
        alt=""
      />

      <div className="text-black gap-6 flex flex-col text-left md:text-left">
        <p className="text-3xl md:text-5xl font-semibold">
          Are you looking to launch your own perfume?
        </p>

        <p className="text-2xl md:text-4xl">
          At our facilities, we make much more than perfumes, we make Private
          Label Perfumes
        </p>
      </div>
    </div>
  );
};

export default Section1;
