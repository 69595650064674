import React from 'react';
import './WhiteLabel.css';

const WhiteLabel = () => {
    return (
        <div>
            <p className="mt-[120px] mb-[50px] text-black text-5xl text-center">
                WHITE LABEL
            </p>
            <div className="w-full h-[50vh] bg-[#C5B4E2] flex justify-center items-center">
                <p className="typing-text w-[80%] text-center text-5xl sm:m-10 text-white">
                    Your Brand, Our Expertise
                </p>
            </div>

            {/* Section 1: Introduction to White Label Manufacturing */}
            <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col lg:flex-row items-center gap-10">
                <div className="sm:w-[50%]">
                    <p className="text-4xl my-6 font-bold">
                        What is White Label Manufacturing?
                    </p>
                    <p className="text-2xl my-3">
                        White label manufacturing allows you to focus on building your brand
                        while we handle the production. We provide a range of high-quality
                        products that you can customize with your branding.
                    </p>
                    <p className="text-2xl my-3">
                        From formulation to packaging, we take care of every step in the
                        manufacturing process, ensuring that each product meets our rigorous
                        quality standards and aligns with your brand's values.
                    </p>
                </div>
                <img
                    className="sm:w-[50%]"
                    src="https://cdn.shopify.com/s/files/1/0573/6241/5800/t/25/assets/06-1631352916771_1200x.jpg?v=1631352918"
                    alt="White Label Manufacturing Process"
                />
            </div>

            {/* Section 2: Benefits of White Label Manufacturing */}
            <div className="w-[90%] sm:w-[70%] mx-auto my-10 flex flex-col lg:flex-row-reverse items-center gap-10">
                <div className="sm:w-[50%]">
                    <p className="text-4xl my-6 font-bold">
                        Why Choose Our White Label Services?
                    </p>
                    <p className="text-2xl my-3">
                        Our white label services provide a fast, reliable way to bring
                        high-quality products to your customers under your brand name. With
                        our extensive experience, we offer seamless solutions that help you
                        scale your business.
                    </p>
                    <p className="text-xl my-3">
                        We use only premium ingredients and adhere to strict quality control
                        measures, ensuring that your products not only look great but also
                        deliver outstanding performance.
                    </p>
                    <p className="text-xl my-3">
                        Whether you’re launching a new line or expanding an existing one, our
                        team is here to support you every step of the way.
                    </p>
                </div>
                <img
                    className="sm:w-[50%]"
                    src="https://cdn4.beautinow.com/wp-content/uploads/2023/08/pexelsnastyasensei954405_7447a398dc444886a49a79093cdf3b13.jpgv=1691390421"
                    alt="White Label Benefits"
                />
            </div>

            {/* Section 3: Our Process */}
            <div className="w-[90%] lg:w-[70%] mx-auto my-10 flex flex-col ">
                <p className="text-4xl my-6 font-bold text-center">
                    Our White Label Process
                </p>
                <p className='text-2xl my-6'>Our white label is a luxury niche fragrance section that curates and blends bespoke fragrances with specially selected raw materials to suit your eccentric needs.</p>
                <img alt='' src='https://freedom-fragrances.com/wp-content/uploads/2024/07/private-label-fragrance-manufacturer-india.jpg'/>
                <p className="text-2xl my-3 mt-10">
                    1. <span className='bg-[#C5B4E2]'>**Consultation**</span>: We discuss your vision and requirements to develop a tailored product plan.
                </p>
                <p className="text-2xl my-3">
                    2. <span className='bg-[#C5B4E2]'>**Product Development**</span>: Our team formulates the product using high-quality ingredients that match your specifications.
                </p>
                <p className="text-2xl my-3">
                    3. <span className='bg-[#C5B4E2]'>**Branding and Packaging**</span>: We provide customizable packaging options to ensure your products reflect your brand identity.
                </p>
                <p className="text-2xl my-3">
                    4. <span className='bg-[#C5B4E2]'>**Production and Quality Control**</span>: We produce your products in our state-of-the-art facilities, adhering to strict quality standards.
                </p>
                <p className="text-2xl my-3">
                    5. <span className='bg-[#C5B4E2]'>**Delivery**</span>: Once the products are ready, we handle logistics to get them to your desired location.
                </p>
            </div>
        </div>
    );
};

export default WhiteLabel;
