// import PaintAnimation from "../PaintingAnimation/PaintingAnimation";
// import paintImage from "../../Images/paint-strokes-yellow.png";
import contract from "../../Images/contract-ai.webp";
import { Link } from "react-router-dom";

const HomeSec6 = () => {
  return (
    <div className="flex flex-col xl:flex-row-reverse justify-center xl:h-[80vh] items-center mt-10 w-[90vw] sm:w-[80vw] xl:w-[75vw] mx-auto">
      <div className="w-[100%] sm:w-[80%] p-2 lg:w-[80%] md:p-10 lg:p-16 my-auto xl:ms-auto">
        <p className="text-3  xl sm:text-4xl lg:text-5xl font-bold lg:w-[100%] mx-auto mb-10 text-secondary">
          Contract Manufacture
        </p>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl ">
          We offer contract manufacturing services tailored to meet your
          specific needs. Whether you're looking to produce perfumes, cosmetics,
          or other personal care products, we provide comprehensive solutions
          that cover every aspect of the manufacturing process, from formulation
          to packaging.
        </p>
        <div className="w-[100%] lg:w-[70%] mb-10 mt-6 md:hidden">
          <img alt="Contract Manufacture" src={contract} />
        </div>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl mt-6">
          Our experienced team ensures high-quality production while adhering to
          your unique requirements, deadlines, and budget.
        </p>
        <div className="mt-10">
          <Link
            to="/contract-manufacture"
            className="relative inline-block border border-secondary hover:text-white text-secondary lg:text-secondary bg-white font-bold py-4 px-6 sm:px-10 sm:py-4 rounded-lg overflow-hidden ease-in-out duration-200 border-[2px] group"
          >
            <span className="absolute inset-0 bg-secondary w-0 h-full transition-all duration-500 ease-out group-hover:w-full"></span>
            <span className="relative z-10">Want To Know More?</span>
          </Link>
        </div>
      </div>
      <div className="w-[90%] lg:w-[70%] mb-10 hidden md:block">
        <img alt="Contract Manufacture" src={contract} />
      </div>
    </div>
  );
};

export default HomeSec6;
