import React, { useEffect, useState } from "react";
// import banner from "../../Images/banner1.jpg";
import bannerVideo from "../../Images/home.mp4";

const Banner = () => {
  const [showImgBanner, setShowImgBanner] = useState();
  const isDesktop = window.innerWidth > 700;

  useEffect(() => {
    setTimeout(() => {
      setShowImgBanner(true);
    }, 7000);
  }, []);

  return (
    <div className="xl:h-[115vh] lg:h-[90vh] sm:h-[40vh] h-[210px]">
      <div>
        <video autoPlay muted loop>
          <source src={bannerVideo} type="video/mp4" />
        </video>
        {/* <div className="overlay"></div> */}
      </div>
    </div>
  );
};

export default Banner;
