// import PaintAnimation from "../PaintingAnimation/PaintingAnimation";
// import paintImage from "../../Images/paint-strokes-purple.png";
import whitelabel from "../../Images/white-label-ai.webp";
import { Link } from "react-router-dom";

const HomeSec5 = () => {
  return (
    <div className="flex flex-col xl:flex-row justify-center xl:h-[80vh] items-center mt-10 w-[90vw] sm:w-[80vw] xl:w-[75vw] mx-auto">
      <div className="w-[100%] sm:w-[80%] p-2 xl:w-[80%] md:p-10 lg:p-16 my-auto">
        <p className="text-3xl sm:text-4xl lg:text-5xl font-bold lg:w-[100%] mx-auto mb-10 text-secondary">
          White Label
        </p>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl ">
          Our white label solutions allow you to sell customized products under
          your brand name. We offer a wide range of high-quality products that
          can be branded to reflect your identity, including fragrances, body
          sprays, air fresheners, and more.
        </p>
        <div className="w-[100%] lg:w-[70%] mb-10 mt-6 md:hidden">
        <img
          alt="White Label Manufacture"
          src={whitelabel}
        />
      </div>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl mt-6">
          With minimal upfront investment, our white label services let you
          expand your product line quickly and efficiently. We handle the
          production and packaging, while you focus on branding and marketing,
          ensuring a seamless experience from start to finish.
        </p>
        <div className="mt-10">
          <Link
            to="white-label-manufacture"
            className="relative inline-block border border-secondary hover:text-white text-secondary lg:text-secondary bg-white font-bold py-4 px-6 sm:px-10 sm:py-4 rounded-lg overflow-hidden ease-in-out duration-200 border-[2px] group"
          >
            <span className="absolute inset-0 bg-secondary w-0 h-full transition-all duration-500 ease-out group-hover:w-full"></span>
            <span className="relative z-10">Want To Know More?</span>
          </Link>
        </div>
      </div>
      <div className="w-[90%] lg:w-[70%] mb-10 hidden md:block">
        <img
          alt="White Label Manufacture"
          src={whitelabel}
        />
      </div>
    </div>
  );
};

export default HomeSec5;
