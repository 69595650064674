import React from 'react';

const ManufacturerSec5 = () => {
    return (
        <div className='flex flex-col justify-center items-center mt-10 w-[90vw] md:w-[80vw] mx-auto'>
            
        </div>
    );
};

export default ManufacturerSec5;