import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home";
import PrivateLabel from "../Pages/PrivateLabel/PrivateLabel";
import Manufacturer from "../Pages/Manufacturer/Manufacturer";
import ContactUs from "../Pages/ContactUs";
import AboutUs from "../Pages/AboutUs";
import PerfumeManufacture from "../Pages/PerfumeManufacture/PerfumeManufacture";
import BodySprayManufacture from "../Pages/BodySpray/BodySprayManufacture";
import AirFreshenerManufacture from "../Pages/AirFreshner/AirFreshenerManufacture";
import WhiteLabel from "../Pages/WhiteLabel/WhiteLabel";
import Contract from "../Pages/Contract/Contract";
import JoinTheTeam from "../Pages/JoinTheTeam";
import MeetTheTeam from "../Pages/MeetTheTeam";
import ScentedMarketing from "../Pages/ScentedMarketing";
import Brands from "../Pages/Brands/Brands";
import Career from "../Pages/Career";
import Apply from "../Pages/Apply";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/private-label",
        element: <PrivateLabel></PrivateLabel>,
      },
      {
        path: "/perfume-manufacturers",
        element: <Manufacturer></Manufacturer>,
      },
      {
        path: "/contact-us",
        element: <ContactUs></ContactUs>,
      },
      {
        path: "/who-we-are",
        element: <AboutUs></AboutUs>,
      },
      {
        path: "/perfume-manufacture",
        element: <PerfumeManufacture></PerfumeManufacture>,
      },
      {
        path: "/body-spray-manufacture",
        element: <BodySprayManufacture></BodySprayManufacture>,
      },
      {
        path: "/air-freshner-manufacture",
        element: <AirFreshenerManufacture></AirFreshenerManufacture>,
      },
      {
        path: "/white-label-manufacture",
        element: <WhiteLabel/>,
      },
      {
        path: "/contract-manufacture",
        element: <Contract/>,
      },
      {
        path: "/join-the-team",
        element: <JoinTheTeam/>,
      },
      {
        path: "/meet-the-team",
        element: <MeetTheTeam/>,
      },
      {
        path: "/scented-marketing",
        element: <ScentedMarketing/>,
      },
      {
        path: "/brands",
        element: <Brands/>,
      },
      {
        path: "/career",
        element: <Career  />,
      },
      {
        path: "/career/apply",
        element: <Apply  />,
      },
    ],
  }
]);

export default router;
