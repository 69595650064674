import React from "react";

const Apply = () => {
  return (
    <div className="sm:w-[80%] mx-auto my-32">
      <p className="text-center text-3xl mb-10">Apply Now</p>
      <div className="relative w-full">
        <iframe
          className="top-0 left-0 w-full h-[300vh] sm:h-[180vh] border-0"
          src="https://docs.google.com/forms/d/e/1FAIpQLScNifg3yIUELSmF242k2-xjS1LvbmfJWgc4nMIW5EkQttyprQ/viewform?embedded=true"
          allowFullScreen
          title="Google Form"
        >
          Loading…
        </iframe>
      </div>
    </div>
  );
};

export default Apply;
