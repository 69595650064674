// import PaintAnimation from "../PaintingAnimation/PaintingAnimation";
// import paintImage from "../../Images/paint-strokes-blue.png";
import bodyspray from "../../Images/body-spray-ai.webp";
import { Link } from "react-router-dom";

const HomeSec2 = () => {
  return (
    <div className="flex flex-col xl:flex-row-reverse justify-center xl:h-[80vh] items-center mt-10 w-[90vw] sm:w-[80vw] xl:w-[75vw] mx-auto">
      <div className="w-[100%] sm:w-[80%] p-2 xl:w-[80%] md:p-10 lg:p-12 xl:p-16 my-auto">
        <p className="text-3xl sm:text-4xl lg:text-5xl font-bold lg:w-[100%] mx-auto mb-10 text-secondary">
          Body Spray
        </p>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl ">
          Our body sprays are expertly crafted with invigorating, long-lasting
          scents that are perfect for daily use. We use high-quality ingredients
          to ensure a refreshing and skin-friendly experience, catering to
          diverse preferences with innovative formulations.
        </p>
        <div className="w-[100%] lg:w-[70%] mb-10 md:hidden mt-6">
        <img
          alt="Body Spray Manufacture"
          src={bodyspray}
        />
      </div>
        <p className="lg:w-[100%] mx-auto text-xl md:text-2xl mt-6">
          At Nextcare, we deliver premium body sprays tailored to your vision,
          ensuring quality and customer satisfaction every step of the way.
        </p>
        <div className="mt-10">
          <Link
            to="/body-spray-manufacture"
            className="relative inline-block border border-secondary hover:text-white text-secondary lg:text-secondary bg-white font-bold py-4 px-6 sm:px-10 sm:py-4 rounded-lg overflow-hidden ease-in-out duration-200 border-[2px] group"
          >
            <span className="absolute inset-0 bg-secondary w-0 h-full transition-all duration-500 ease-out group-hover:w-full"></span>
            <span className="relative z-10">Want To Know More?</span>
          </Link>
        </div>
      </div>
      <div className="w-[90%] lg:w-[70%] mb-10 hidden md:block">
        <img
          alt="Body Spray Manufacture"
          src={bodyspray}
        />
      </div>
    </div>
  );
};

export default HomeSec2;
